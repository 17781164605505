<template>
  <div>
    <b-modal v-model="infoModalePrice.show">
      <template #modal-title>
        Modification du prix unitaire du crédit.
      </template>

      <b-form-group>
        <label>Prix unitaire </label>
        <b-form-input
          v-model="info.tarif_credit"
          :type="type"
          min="1"
          max="200"
          class="d-inline-block"
        />
      </b-form-group>

      <template #modal-footer>
        <button
          v-b-modal.modal-close_visit
          class="btn btn-danger btn-sm m-1"
          type="submit"
          @click.prevent="closeModale"
        >
          Fermer
        </button>
        <button
          v-b-modal.modal-close_visit
          class="btn btn-success btn-sm m-1"
          @click.prevent="updateTarifCredit"
        >
          OK
        </button>
      </template>

    </b-modal>
  </div>
</template>

<script>
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
  },
  props: {
    infoModalePrice: {
      type: Object,
      default() {
        return {}
      },
    },
    messageSuccessTarifCreditUpdate: {
      type: String,
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      modalShow: false,
      type: 'number',
      info: {
        tarif_credit: null,
      },
    }
  },
  methods: {
    updateTarifCredit() {
      const newTarifCredit = Number(this.info.tarif_credit)
      this.$store.dispatch('user/updateTarifCredit', { newTarifCredit, id: this.infoModalePrice.id_user })
      this.infoModalePrice.show = false
      this.info.tarif_credit = ' '
    },
    closeModale() {
      this.infoModalePrice.show = false
    },
  },
}
</script>

<style scoped>
 .modal-content,.modale{
    margin-top:500px !important;
 }
</style>
